vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Neuromoduladores para el bruxismo</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="rinomodelación" src="../../assets/bruxismo.jpg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>Los <span class="p-text-bold">neuromoduladores</span>, como la toxina botulínica, ofrecen un alivio
                        eficaz para el <span class="p-text-bold">bruxismo</span>, una condición en la que se aprietan o
                        rechinan los dientes involuntariamente. Estos tratamientos relajan los músculos de la mandíbula,
                        reduciendo la tensión y los daños dentales. Sin embargo, los pacientes pueden tener preguntas, como:
                        </p>

                        <p><span class="p-text-bold">1.</span> ¿Está seguro? <span class="p-text-bold">Sí</span>, es seguro
                            y ha demostrado ser eficaz en el tratamiento del bruxismo.</p>

                        <p><span class="p-text-bold">2.</span> ¿Cuánto tiempo duran los efectos? Por lo general, los
                            resultados duran de <span class="p-text-bold">3 a 4 meses</span>.</p>

                        <p><span class="p-text-bold">3.</span> ¿Es doloroso el procedimiento? Las inyecciones son <span
                                class="p-text-bold">mínimamente dolorosas</span> y suelen ser bien toleradas.</p>

                        <p><span class="p-text-bold">4.</span> ¿Cuándo veré mejoras en mi bruxismo? Los resultados comienzan
                            a notarse en <span class="p-text-bold">unos días</span>, con mejoras continuas en las semanas
                            siguientes.</p>

                        <p><span class="p-text-bold">5.</span> ¿Cómo afecta mi rutina diaria? Puede reanudar sus actividades
                            normales <span class="p-text-bold">inmediatamente</span> después del tratamiento.</p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'RinomodelacionVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>